import { AppConfig } from '../../app.config.module';
import { ShareDataService } from './share-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
import * as i3 from "./share-data.service";
var SendEdiToSarsService = /** @class */ (function () {
    function SendEdiToSarsService(http, config, shareDataService) {
        this.http = http;
        this.config = config;
        this.shareDataService = shareDataService;
    }
    SendEdiToSarsService.prototype.sendBillOfEntry = function (file, fileType) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (fileType === 'imports') {
            return this.http.post(this.config.filesApiEndpoint + 'api/clearingFile/' + file.id + '/billOfEntries/edi', { headers: headers });
        }
        else if (fileType === 'exbond') {
            return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles/' + file.id + '/exBondBillOfEntries/edi', { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/exportFile/' + file.id + '/exportBillOfEntries/edi', { headers: headers });
        }
    };
    SendEdiToSarsService.prototype.sendSelectedRcgBillsOfEntry = function (selectedBoes) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + "api/clearingFile/sendSelectedRcgBoes", selectedBoes, { headers: headers });
    };
    SendEdiToSarsService.prototype.sendRcgManifestBillsOfEntry = function (mawbNo) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + "api/clearingFile/sendManifestRcgBoes", null, { headers: headers, params: { mawbNo: mawbNo } });
    };
    SendEdiToSarsService.prototype.sendManifest = function (roadManifest) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + 'api/manifests/road/' + roadManifest.id + '/edi', { headers: headers });
    };
    SendEdiToSarsService.prototype.sendShipsManifest = function (voyageMasterFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + 'api/manifests/sea/' + voyageMasterFile.id + '/edi', { headers: headers });
    };
    SendEdiToSarsService.prototype.requestCusresRetransmission = function (fileId, id, fileType) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (fileType === 'imports') {
            return this.http.post(this.config.filesApiEndpoint + 'api/clearingFile/' + fileId + '/billOfEntries/' + id + '/edi/retransmission', { headers: headers });
        }
        else if (fileType === 'exbond') {
            return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles/' + fileId + '/exBondBillOfEntries/' + id + '/edi/retransmission', { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + 'api/exportFile/' + fileId + '/billOfEntries/' + id + '/edi/retransmission', { headers: headers });
        }
    };
    SendEdiToSarsService.prototype.sendForwardingFile = function (forwardingFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles/' + forwardingFile.id + '/edi', { headers: headers });
    };
    SendEdiToSarsService.prototype.sendForwardingFileCostco = function (forwardingFile) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles/' + forwardingFile.id + '/costco', { headers: headers });
    };
    SendEdiToSarsService.prototype.sendEdiTest = function () {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.config.filesApiEndpoint + 'api/clearingFile/billOfEntries/edi/test', { headers: headers });
    };
    SendEdiToSarsService.ngInjectableDef = i0.defineInjectable({ factory: function SendEdiToSarsService_Factory() { return new SendEdiToSarsService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG), i0.inject(i3.ShareDataService)); }, token: SendEdiToSarsService, providedIn: "root" });
    return SendEdiToSarsService;
}());
export { SendEdiToSarsService };
