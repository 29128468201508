var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { BillOfEntryService } from '../services/bill-of-entry.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthorisationService } from '../../../subjects/authorisation-behaviour-subject';
import { ImporterService } from '../services/importer.service';
import { AgentService } from '../services/agent.service';
import { BusinessEntity } from '../../digi-business-entity/models/business-entity';
import { BusinessEntityService } from '../../digi-business-entity/services/business-entity.service';
import { NotificationService } from '../services/notification.service';
import { SendEdiToSarsService } from '../services/send-edi-to-sars.service';
import { InvoiceService } from '../services/invoice.service';
import { ClearingFileService } from '../services/clearing-file.service';
import { OverlayPanel } from 'primeng/primeng';
var TransportMethods;
(function (TransportMethods) {
    TransportMethods["SC"] = "sea";
    TransportMethods["SE"] = "sea";
    TransportMethods["AC"] = "air";
    TransportMethods["AE"] = "air";
    TransportMethods["RC"] = "road";
    TransportMethods["RE"] = "road";
    TransportMethods["TE"] = "rail";
})(TransportMethods || (TransportMethods = {}));
var SarsDashboardComponent = /** @class */ (function () {
    function SarsDashboardComponent(localAgentService, importerService, billOfEntryService, router, route, messageService, sendEdiToSarsService, authorisationService, confirmationService, businessEntityService, invoiceService, clearingFileService) {
        var _this = this;
        this.localAgentService = localAgentService;
        this.importerService = importerService;
        this.billOfEntryService = billOfEntryService;
        this.router = router;
        this.route = route;
        this.messageService = messageService;
        this.sendEdiToSarsService = sendEdiToSarsService;
        this.authorisationService = authorisationService;
        this.confirmationService = confirmationService;
        this.businessEntityService = businessEntityService;
        this.invoiceService = invoiceService;
        this.clearingFileService = clearingFileService;
        this.selectedClearingFile = new EventEmitter();
        this.filteredBusinessEntities = [];
        this.filterRejections = false;
        this.router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                _this.indicator = _this.route.snapshot.paramMap.get('indicator');
                _this.rcgMode = false;
                _this.loadBillOfEntries();
            }
        });
    }
    SarsDashboardComponent.prototype.ngOnInit = function () {
        this.cols = [
            { field: 'ediMessage.clearingFileNumber', header: 'File Number', width: '8%' },
            { field: 'ediMessage.sequenceNumber', header: 'Seq', width: '3%' },
            { field: 'ediMessage.lrn', header: 'LRN', width: '10%' },
            { field: '', header: 'MRN', width: '7%' },
            { field: '', header: 'CPC', width: '3%' },
            { field: 'ediMessage.dateEdiSubmitted', header: 'Sent On', width: '6%' },
            { field: '', header: 'MDN', width: '3%' },
            { field: '', header: 'CONTRL Recv On', width: '6%' },
            { field: '', header: 'CONTRL', width: '3%' },
            { field: '', header: 'CUSRES Recv On', width: '6%' },
            { field: '', header: 'CUSRES', width: '12%' },
        ];
        this.rcgCols = [
            { field: 'ediMessage.clearingFileNumber', header: 'File Number', width: '10%' },
            { field: 'ediMessage.sequenceNumber', header: 'Seq', width: '3%' },
            { field: 'ediMessage.lrn', header: 'LRN', width: '10%' },
            { field: '', header: 'MRN', width: '7%' },
            { field: '', header: 'CPC', width: '3%' },
            { field: 'ediMessage.dateEdiSubmitted', header: 'Sent On', width: '10%' },
            { field: '', header: 'MDN', width: '4%' },
            { field: '', header: 'CONTRL Recv On', width: '6%' },
            { field: '', header: 'CONTRL', width: '4%' },
            { field: '', header: 'CUSRES Recv On', width: '6%' },
            { field: '', header: 'CUSRES', width: '12%' },
        ];
        this.rcgMode = false;
        this.mawbFilter = null;
        this.selectedBoes = [];
    };
    SarsDashboardComponent.prototype.onLazyLoad = function (event, indicator) {
        var _this = this;
        this.authorisationService.getLoggedInUser().subscribe(function (user) {
            if (user) {
                _this.loading = true;
                var pageNumber = event.first / event.rows;
                if (user && user.attributes && user.attributes['branch']) {
                    _this.branchCode = user.attributes['branch'][0];
                }
                if (_this.rcgMode && indicator === 'imports') {
                    if (_this.mawbFilter && _this.mawbFilter !== '') {
                        _this.billOfEntryService.findAllRcgEdiMessages(pageNumber, event.rows, _this.branchCode, _this.mawbFilter, _this.filterRejections)
                            .subscribe(function (data) {
                            _this.billsOfEntry = data._embedded.importBillOfEntries;
                            _this.totalRecords = data.page.totalElements;
                            _this.loading = false;
                            // this.sendTrackingEvent();
                        }, function (err) {
                            _this.loading = false;
                        });
                    }
                    else {
                        _this.billsOfEntry = [];
                        _this.loading = false;
                    }
                }
                else {
                    _this.billOfEntryService.findAllEdiMessages(pageNumber, event.rows, indicator, _this.branchCode)
                        .subscribe(function (data) {
                        if (indicator === 'imports') {
                            _this.billsOfEntry = data._embedded.importBillOfEntries;
                        }
                        else if (indicator === 'exports') {
                            _this.billsOfEntry = data._embedded.exportBillOfEntries;
                        }
                        else {
                            _this.billsOfEntry = data._embedded.exBondBillOfEntries;
                        }
                        _this.totalRecords = data.page.totalElements;
                        _this.loading = false;
                    }, function (err) {
                        _this.loading = false;
                    });
                }
            }
        });
    };
    SarsDashboardComponent.prototype.canSubmitToSars = function () {
        return this.mawbFilter && this.rcgMode && this.billsOfEntry && this.billsOfEntry.length > 0;
    };
    SarsDashboardComponent.prototype.loadBillOfEntries = function () {
        this.selectedBoes = [];
        this.onLazyLoad({ first: 0, rows: 10 }, this.indicator);
    };
    SarsDashboardComponent.prototype.controlStatus = function (billOfEntry) {
        if (billOfEntry) {
            if (billOfEntry.contrl) {
                if (billOfEntry.contrl.messageStatus === 'ACKNOWLEDGED' && billOfEntry.contrl.interchangeStatus === 'ACKNOWLEDGED') {
                    return true;
                }
            }
        }
        return false;
    };
    SarsDashboardComponent.prototype.mdnResponseStatus = function (billOfEntry) {
        if (billOfEntry) {
            if (billOfEntry.mdnResponse) {
                if (!billOfEntry.mdnResponse.errorMessage) {
                    return true;
                }
            }
        }
        return false;
    };
    SarsDashboardComponent.prototype.loadClearingFile = function (fileNumber, sequenceNumber) {
        var transportCode = fileNumber.substring(0, 2);
        var path;
        if (this.indicator === 'imports') {
            path = TransportMethods[transportCode];
        }
        else if (this.indicator === 'exports') {
            path = "export/" + TransportMethods[transportCode];
        }
        else {
            path = "exbond";
        }
        this.router.navigate([path, 'register', fileNumber, sequenceNumber]);
    };
    SarsDashboardComponent.prototype.getStatus = function (ediMessage) {
        if (ediMessage && ediMessage.cusres) {
            if (ediMessage.cusres.customsStatus === 'DETAIN_OTHER_OGA_OTHER_GOVERNMENT_AGENCY' || ediMessage.cusres.customsStatus === 'CUSTOMS_STOP_AWAITING_OGA_OUTCOME' && ediMessage.cusres.customsStatusError) {
                return ediMessage.cusres.customsStatusError;
            }
            else {
                return ediMessage.cusres.customsStatus;
            }
        }
    };
    SarsDashboardComponent.prototype.openSearchCriteriaModal = function () {
        this.displaySearchModal = true;
    };
    SarsDashboardComponent.prototype.closeModal = function () {
        this.displaySearchModal = false;
    };
    SarsDashboardComponent.prototype.searchBoes = function () {
        var _this = this;
        this.loading = true;
        this.billOfEntryService
            .findBoesByParameters(this.mawbNo, this.declarantName, this.foreignDeclarantName, this.containerNo, this.lrnNo, this.fileNo, this.referenceNo, this.mrnNo, this.caseNo, this.supplierInvNo, this.clearingFileId, this.fromDate, this.toDate, this.branchCode, this.indicator)
            .subscribe(function (data) {
            _this.billsOfEntry = data;
            _this.loading = false;
            _this.displaySearchModal = false;
        }, function (err) {
            _this.loading = false;
            _this.displaySearchModal = false;
        });
    };
    SarsDashboardComponent.prototype.searchBusinessEntity = function (event) {
        var _this = this;
        this.businessEntityService.findBusinessEntityStartsWith(event.query).subscribe(function (data) {
            _this.filteredBusinessEntities = data;
        });
    };
    SarsDashboardComponent.prototype.searchImporterEntityCode = function (event) {
        var _this = this;
        this.businessEntityService.findBusinessEntityImporterCode(event.query).subscribe(function (data) {
            _this.filteredBusinessEntities = data;
        });
    };
    SarsDashboardComponent.prototype.loadRcgBoes = function (event) {
        this.rcgMode = event;
        if (!this.rcgMode) {
            this.mawbFilter = null;
        }
        this.loadBillOfEntries();
    };
    SarsDashboardComponent.prototype.loadRcgRejectionBoes = function (event) {
        this.filterRejections = event;
        this.loadBillOfEntries();
    };
    SarsDashboardComponent.prototype.submitBoesToSars = function (isFullManifestSelection) {
        var _this = this;
        if ((this.selectedBoes && this.selectedBoes.length > 0) || isFullManifestSelection) {
            var message = 'Are you sure that you want to send EDI for selected files?';
            if (isFullManifestSelection) {
                message = 'Are you sure that you want to send EDI for entire manifest?';
            }
            this.confirmationService.confirm({
                message: message,
                accept: function () {
                    _this.messageService.successNotify('Sending EDI...');
                    if (isFullManifestSelection) {
                        _this.sendEdiToSarsService.sendRcgManifestBillsOfEntry(_this.mawbFilter).subscribe(function (data) {
                            _this.messageService.successNotify('EDI sent');
                            _this.loadBillOfEntries();
                        }, function (error) {
                            console.error('Error sending EDI:', error);
                            _this.loadBillOfEntries();
                        });
                    }
                    else {
                        _this.sendEdiToSarsService.sendSelectedRcgBillsOfEntry(_this.selectedBoes).subscribe(function (data) {
                            _this.messageService.successNotify('EDI sent');
                            _this.loadBillOfEntries();
                        }, function (error) {
                            console.error('Error sending EDI:', error);
                            _this.loadBillOfEntries();
                        });
                    }
                }
            });
        }
        else {
            this.messageService.errorNotify('No files selected for EDI!');
        }
    };
    SarsDashboardComponent.prototype.submitBoesToSarsAsync = function (isFullManifestSelection) {
        return __awaiter(this, void 0, void 0, function () {
            var message, confirmSubmission, response, _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((this.selectedBoes && this.selectedBoes.length > 0) || isFullManifestSelection)) return [3 /*break*/, 10];
                        message = isFullManifestSelection
                            ? 'Are you sure that you want to send EDI for entire manifest?'
                            : 'Are you sure that you want to send EDI for selected files?';
                        return [4 /*yield*/, this.confirmBoesSubmission(message)];
                    case 1:
                        confirmSubmission = _b.sent();
                        if (!confirmSubmission)
                            return [2 /*return*/];
                        this.messageService.successNotify('Sending EDI...');
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 7, 8, 9]);
                        if (!isFullManifestSelection) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.sendEdiToSarsService.sendRcgManifestBillsOfEntry(this.mawbFilter).toPromise()];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.sendEdiToSarsService.sendSelectedRcgBillsOfEntry(this.selectedBoes).toPromise()];
                    case 5:
                        _a = _b.sent();
                        _b.label = 6;
                    case 6:
                        response = _a;
                        console.log('Backend Response:', response);
                        if (response.success) {
                            this.messageService.successNotify('EDI sent successfully!');
                        }
                        else {
                            this.messageService.errorNotify('Operation successful but encountered delays.');
                        }
                        return [3 /*break*/, 9];
                    case 7:
                        error_1 = _b.sent();
                        console.error('Error sending EDI:', error_1);
                        this.messageService.errorNotify("Error: " + (error_1.message || 'Failed to send EDI'));
                        return [3 /*break*/, 9];
                    case 8:
                        this.loadBillOfEntries();
                        return [7 /*endfinally*/];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        this.messageService.errorNotify('No files selected for EDI!');
                        _b.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    SarsDashboardComponent.prototype.confirmBoesSubmission = function (message) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.confirmationService.confirm({
                message: message,
                accept: function () { return resolve(true); },
                reject: function () { return resolve(false); },
            });
        });
    };
    SarsDashboardComponent.prototype.selectedImporterOrExporterBusinessEntity = function (value) {
        this.declarantName = value.name;
    };
    SarsDashboardComponent.prototype.selectedSupplierBusinessEntity = function (value) {
        this.foreignDeclarantName = value.name;
    };
    SarsDashboardComponent.prototype.search = function (event) {
        var _this = this;
        this.invoiceService.findAllInvoicesStartingWith(event.query, this.indicator).subscribe(function (invoices) {
            _this.filteredInvoices = invoices;
        });
    };
    SarsDashboardComponent.prototype.select = function (value) {
        var _this = this;
        this.invoiceService.findClearingFileByInvoiceSelfRefLink(value).subscribe(function (clearingFile) {
            _this.clearingFileId = clearingFile.id;
        });
    };
    SarsDashboardComponent.prototype.selectCorrection = function (value) {
        this.clearingFileService.selectClearingFile(value);
        this.correctionList.hide();
    };
    SarsDashboardComponent.prototype.selectInitial = function (value) {
        if (value.type !== 'Correction') {
            this.clearingFileService.selectClearingFile(value);
        }
    };
    SarsDashboardComponent.prototype.clearAll = function () {
        this.selectedClientInvoice = undefined;
    };
    return SarsDashboardComponent;
}());
export { SarsDashboardComponent };
