import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntryBehaviourSubject} from '../../../../subjects/billOfEntry-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../../subjects/clearingfile-behaviour-subject';
import {BillOfEntry, EDIErrorDetails, EDIMessage, ErrorDetail, PaymentType} from '../../models/billOfEntries';
import {ClearingFile} from '../../models/clearingFile';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {ClearingFileService} from '../../services/clearing-file.service';
import {NotificationService} from '../../services/notification.service';
import {ShareDataService} from '../../services/share-data.service';
import {FileType} from '../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../models/exportFile';
import {Router} from '@angular/router';
import {SendEdiToSarsService} from '../../services/send-edi-to-sars.service';
import {SelectItem} from 'primeng/primeng';
import {PaymentTypeService} from '../../services/payment-type.service';
import {SendTrackingEventRequest} from '../../models/file';

@Component({
  selector: 'digi-confirmation-section',
  templateUrl: './confirmation-section.component.html',
  styleUrls: ['./confirmation-section.component.scss']
})
export class ConfirmationSectionComponent implements OnInit, OnDestroy, AfterViewInit {
  currentBillOfEntry: BillOfEntry;
  file: ClearingFile | ExportFile;
  display = false;
  contrl: EDIMessage;
  cusres: EDIMessage;
  cusdecs: String[];
  uploadedFiles: any = [];
  colsForContrl: any[];
  colsForCusres: any[];
  colsForCusdec: any[];
  colsForErrors: any[];
  subscription: Subscription;
  boesubscription: Subscription;
  timerId: any;
  @Input() fileType: FileType;
  errors: ErrorDetail[];
  statuses: SelectItem[];
  releaseStatuses: SelectItem[];
  paymentTypes: PaymentType[];
  stopStatuses: SelectItem[];
  controlFailed: boolean;

  constructor(private billOfEntryService: BillOfEntryService,
              private shareDataService: ShareDataService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
              private clearingFileService: ClearingFileService,
              private messageService: NotificationService,
              private router: Router,
              private sendEdiToSarsService: SendEdiToSarsService,
              private paymentTypeService: PaymentTypeService
  ) {
  }

  ngOnInit() {
    this.statuses = [
      {
        label: '',
        value: null
      },
      {
        label: 'Outstanding',
        value: 'OUTSTANDING'
      },
      {
        label: 'Accepted',
        value: 'ACCEPTED'
      },
      {
        label: 'Rejected',
        value: 'REJECTED'
      }
    ];
    this.releaseStatuses = [
      {
        label: '',
        value: null
      },
      {
        label: 'Detained Other',
        value: 'D'
      },
      {
        label: 'Released',
        value: 'R'
      },
      {
        label: 'Stopped or Detained',
        value: 'S'
      }
    ];
    this.stopStatuses = [
      {
        label: '',
        value: null
      },
      {
        label: 'Anti-Smuggling',
        value: 'ANTI_SMUGGLING'
      },
      {
        label: 'Border Police',
        value: 'BORDER_POLICE'
      }
    ];
    this.initialiseFields();

    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject
        .getClearingFile()
        .subscribe(cf => {
          this.file = cf;
        });
    } else {
      this.subscription = this.exportFileBehaviourSubject
        .getExportFile()
        .subscribe(ef => {
          this.file = ef;
        });
    }

    this.boesubscription = this.billOfEntryBehaviourSubject
      .getBillOfEntry()
      .subscribe(boe => {
        // New BoE so reset
        this.initialiseFields();

        this.currentBillOfEntry = boe;
        this.cusdecs = boe.cusdecs;
        this.addMessageToCusdecs(boe.cusDec);
        this.cusdecs.reverse();

        if (boe.contrl) {
          boe.contrl.messages.reverse();
          this.contrl = boe.contrl;
          this.checkContrlFailure(this.contrl.messages[0]);
        }

        if (boe.cusres) {
          boe.cusres.messages.reverse();
          this.cusres = boe.cusres;
          clearInterval(this.timerId);
          if (boe.cusres.errorDetails) {
            this.errors = this.getErrors(boe.cusres.errorDetails);
          }
        }

      });


    this.colsForContrl = [
      {header: 'Contrl message(s)'}
    ];

    this.colsForCusdec = [
      {header: 'Cusdec message'}
    ];

    this.colsForCusres = [
      {header: 'Cusres message(s)'}
    ];

    this.colsForErrors = [
      {field: 'code', header: 'Error Code', width: '20%'},
      {field: 'lineNumber', header: 'Line Number', width: '20%'},
      {field: 'description', header: 'Description', width: '60%'}
    ];
  }

  ngAfterViewInit() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    if (this.currentBillOfEntry && !this.currentBillOfEntry.markedAsSubmitted) {
      this.startRefreshPoller();
    }
  }

  private initialiseFields() {
    this.contrl = new EDIMessage();
    this.cusres = new EDIMessage();
    this.contrl.messages = [];
    this.cusres.messages = [];
    this.cusdecs = [];
  }

  private addMessageToCusdecs(cusdec) {
    const foundMessage = this.cusdecs.find(m => m === cusdec);
    if (!foundMessage) {
      this.cusdecs.push(cusdec);
    }
  }

  startRefreshPoller() {
    console.log('starting refresh poller...');
    // repeat with the interval of 5 seconds
    this.timerId = setInterval(() => this.refresh(), 5000);

    // after 60 seconds stop
    setTimeout(() => {
      clearInterval(this.timerId);
      console.log('stopped refresh poller after 64 seconds');
    }, 64000);
  }

  refresh() {
    console.log('refreshing BoE...');
    this.retrieveBillOfEntry();
  }

  retrieveBillOfEntry() {
    this.billOfEntryService.retrieveBillOfEntry(this.currentBillOfEntry).subscribe(data => {
      if (data.contrl) {
        if (!this.contrl) { // current contrl is blank
          this.messageService.successNotify('EDI CONTRL received');
        }
      }

      if (data.cusres) {
        this.messageService.successNotify('EDI CUSRES received');
      }
      this.billOfEntryBehaviourSubject.addBillOfEntry(data);
    });
  }

  ngOnDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    this.subscription.unsubscribe();
    this.boesubscription.unsubscribe();
  }

  navigateToManifest() {
    this.router.navigateByUrl(`export/road/manifest/${encodeURIComponent(this.currentBillOfEntry.transportDocNo)}`);
  }

  isProceedToBorderStatus() {
    return this.cusres && this.cusres.customsStatus === 'PROCEED_TO_BORDER_SACU_CLEARANCE';
  }

  requestCusresRetransmission() {
    if (this.currentBillOfEntry.id) {
      this.sendEdiToSarsService.requestCusresRetransmission(this.file.id, this.currentBillOfEntry.id, this.fileType).subscribe(data => {
        this.messageService.successNotify('CUSRES Retransmission Requested');
      });
    }
  }

  getErrors(errorDetails: EDIErrorDetails[]) {
    const errors: ErrorDetail[] = [];
    const regex = /UNZ\+(\d+)\+([^']*)/gm;
    const cusres = this.cusres.messages[0];
    const matches = regex.exec(cusres);
    const messageRefNumber = matches[2];
    errorDetails.filter(el => el.messageRefNumber === messageRefNumber).forEach((el) => {
      const error = new ErrorDetail();
      error.code = el.errorNumber[0].errorCode;
      error.description = el.errorDescriptions[0];
      error.lineNumber = el.errorPointDetails.lineNumber;
      errors.push(error);
    });
    return errors;
  }

  getSch1P1() {
    const schedulePart = this.currentBillOfEntry.schedulePartTotals.find(el => el.schedulePart.code === '11');
    return schedulePart ? schedulePart.totalValue : 0;
  }

  searchPaymentTypes() {
    this.paymentTypeService.getPaymentType().subscribe(data => {
      this.paymentTypes = data._embedded.paymentType;
    });
  }

  selectPaymentType(value: PaymentType) {
    this.currentBillOfEntry.paymentType = value;
  }

  private checkContrlFailure(message: string) {
    message = message.replace(/\r\n/g, '');
    const segments = message.split('\'');
    const uciSegment = segments.find(el => el.startsWith('UCI'));
    const ucmSegment = segments.find(el => el.startsWith('UCM'));

    if (uciSegment.includes('+4+') || ucmSegment.includes('+4+')) {
      this.controlFailed = true;
    }
  }
}
