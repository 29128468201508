import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app.config.module';
import { ShareDataService } from './share-data.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {BillOfEntry} from '../models/billOfEntries';
import {ClearingFile} from '../models/clearingFile';
import {ExportFile} from '../models/exportFile';
import {FileType} from '../models/enumerations';
import {RoadManifest} from '../models/roadManifest';
import {VoyageMasterFile} from '../models/voyageFile';
import {ExBondFile} from '../models/exBondFile';
import {ForwardingFile} from '../models/ForwardingFile';

@Injectable({
  providedIn: 'root'
})
export class SendEdiToSarsService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig,
              private shareDataService: ShareDataService) {
  }

  sendBillOfEntry(file: ClearingFile|ExportFile|ExBondFile, fileType: FileType): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (fileType === 'imports') {
      return this.http.post(this.config.filesApiEndpoint + 'api/clearingFile/' + file.id + '/billOfEntries/edi', {headers});
    } else if (fileType === 'exbond') {
      return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles/' + file.id + '/exBondBillOfEntries/edi', {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/exportFile/' + file.id + '/exportBillOfEntries/edi', {headers});
    }
  }

  sendSelectedRcgBillsOfEntry(selectedBoes: BillOfEntry[]) {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json'
      });
      return this.http.post(
          `${this.config.filesApiEndpoint}api/clearingFile/sendSelectedRcgBoes`,
          selectedBoes,
          { headers }
      );
  }


  sendRcgManifestBillsOfEntry(mawbNo: string) {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json'
      });
      return this.http.post(
          `${this.config.filesApiEndpoint}api/clearingFile/sendManifestRcgBoes`,
          null,
          { headers, params: { mawbNo } }
      );
  }

  sendManifest(roadManifest: RoadManifest) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.filesApiEndpoint + 'api/manifests/road/' + roadManifest.id + '/edi', {headers});
  }

  sendShipsManifest(voyageMasterFile: VoyageMasterFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.filesApiEndpoint + 'api/manifests/sea/' + voyageMasterFile.id + '/edi', {headers});
  }

  requestCusresRetransmission(fileId: string, id: string, fileType: FileType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (fileType === 'imports') {
      return this.http.post(this.config.filesApiEndpoint + 'api/clearingFile/' + fileId + '/billOfEntries/' + id + '/edi/retransmission', {headers});
    } else if (fileType === 'exbond') {
      return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles/' + fileId + '/exBondBillOfEntries/' + id + '/edi/retransmission', {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/exportFile/' + fileId + '/billOfEntries/' + id + '/edi/retransmission', {headers});
    }
  }

  sendForwardingFile(forwardingFile: ForwardingFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles/' + forwardingFile.id + '/edi', {headers});
  }

  sendForwardingFileCostco(forwardingFile: ForwardingFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles/' + forwardingFile.id + '/costco', {headers});
  }

  sendEdiTest() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.config.filesApiEndpoint + 'api/clearingFile/billOfEntries/edi/test', {headers});
  }
}

