import {Component, Inject, OnInit, OnDestroy, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntryMarksAndNumbers} from '../../../models/billOfEntries';
import {PackType} from '../../../models/roadManifest';
import {
  Address,
  Airline,
  CollectPrepaid,
  Currency,
  CustomsRadioCallSign,
  DepotTerminal,
  Freight,
  OceanLine,
  PortDetail,
  TariffCode,
  CustomsValue,
  Container,
  Supplier
} from '../../../models/file';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {ForwardingFileBehaviourSubject} from '../../../../../subjects/forwarding-file-behaviour-subject';
import {AgentService} from '../../../services/agent.service';
import {PackTypeService} from '../../../services/pack-type.service';
import {NotificationService} from '../../../services/notification.service';
import {ForwardingFileValidationService} from '../../../services/forwarding-file-validation.service';
import {ValidationResult} from '../../../services/validation.service';
import {
  ForwardingFile,
  MasterBillMeasures,
  RunningTotals,
  TrackingDetails,
  ForwardingTransportDocument,
  TransShipmentDetails, DigiScan, SendTrackingEventRequest
} from '../../../models/ForwardingFile';
import {DepotTerminalService} from '../../../services/depot-terminal.service';
import {TariffService} from '../../../services/tariff.service';
import {ForwardingFileService} from '../../../services/forwarding-file.service';
import {TransportService} from '../../../../digi-air/services/transport.service';
import {GoodsDescription, Package} from '../../../models/voyageFile';
import {ClearingFile, Importer} from '../../../models/clearingFile';
import {OceanlineService} from '../../../../digi-ocean/services/oceanline.service';
import {CustomsRadioCallSignService} from '../../../services/customs-radio-call-sign.service';
import {APP_CONFIG, AppConfig} from '../../../../app.config.module';
import {Router, ActivatedRoute} from '@angular/router';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {ShareDataService} from '../../../services/share-data.service';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {CurrencyService} from '../../../services/currency.service';
import {Branch} from '../../../models/company';
import {CompanyService} from '../../../services/company.service';
import {BusinessEntity, CustomsTraderType} from '../../../../digi-business-entity/models/business-entity';
import {ImporterService} from '../../../services/importer.service';
import {log} from 'util';

@Component({
  selector: 'digi-manifests',
  templateUrl: './manifests.component.html',
  styleUrls: ['./manifests.component.scss']
})
export class ManifestsComponent implements OnInit, OnDestroy {
  @Input('currency') currency: Currency;
  @Input() required = true;
  @Input() readonly: boolean;
  @Input() label;
  @Output() onSelect = new EventEmitter();
  private onSelectUsed = false;
  activeIndex = 0;
  forwardingFileServiceSubscription: Subscription;
  forwardingFile: ForwardingFile;
  callPurposes: string[];
  selectedHouseBill: ForwardingTransportDocument;
  cols: any[];
  generatedFiles: any[];
  selectedPackage: Package;
  digiScan: DigiScan;
  filteredAirlines: any[];
  filteredLocalForwarders: any[];
  filteredDepotTerminals: DepotTerminal[];
  filteredBusinessEntities: any[];
  filteredPackTypes: PackType[];
  filteredTariffCodes: TariffCode[];
  filteredOceanLines: OceanLine[];
  filteredCustomsRadioCallSigns: CustomsRadioCallSign[];

  displayTrackingDialog: any;
  isHouseBill: boolean;
  transportDocumentToTrack: ForwardingTransportDocument;
  showTrackingProgressBar: any = false;
  display = false;

  private file: any;
  private hawbReportsSubscription: Subscription;
  showProgressBar: boolean;

  status: string;
  currentStatus: string;
  showTrackingButton = false;
  tdSubscription: Subscription;
  validationMessagesForHawbNo: any[] = [];
  hawbFilter: string;
  houseBillSelection: ForwardingTransportDocument[];
  tableFirst = 0;
  clearingFile: ClearingFile;
  selectedCurrency: Currency;
  filteredCurrencies = [];
  subscription: Subscription;
  displayCustomsValuePanel: boolean;
  displaySupplierInvoicePanel: boolean;
  displayTariffCodesPanel: boolean;
  displayDutiesAndTaxesPanel: boolean;
  displayImporterModal = false;
  importerAddresses = [];
  importers: Importer[];
  editingLine = -1;
  // tslint:disable-next-line:max-line-length
  rows: DigiScan[] = [{noOfItems: null, descriptionOfGoods: '', length: null, width: null, height: null, totalMass: null, volMass: null}];
  disableButton: boolean;
  showFilesDialog: boolean;
  pdfSrcc: string;
  searchedTariff: string;

  constructor(
    private companyService: CompanyService,
    private importerService: ImporterService,
    private currencyService: CurrencyService,
    private shareDataService: ShareDataService,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private messageService: NotificationService,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private forwardingFileService: ForwardingFileService,
    private notificationService: NotificationService,
    private localAgentService: AgentService,
    private depotTerminalService: DepotTerminalService,
    private businessEntityService: BusinessEntityService,
    private transportService: TransportService,
    private packTypeService: PackTypeService,
    private tariffService: TariffService,
    private oceanLineService: OceanlineService,
    private customsRadioCallSignService: CustomsRadioCallSignService,
    @Inject(APP_CONFIG) public config: AppConfig,
    private router: Router,
    private route: ActivatedRoute,
    private forwardingFileValidationService: ForwardingFileValidationService,
  ) { }

  ngOnInit() {
    this.cols = [
      {header: 'HAWB No'},
      {header: 'Importer'},
      {header: 'Client Ref No'},
      {header: 'Amount'}
    ];

    if (this.config.enableTracking) {
      this.cols.push({header: 'Tracking'});
    }

    this.createNewHawb();
    this.displayCustomsValue();
    this.displaySupplierInvoiceDetails();
    this.displayTariffCodesDetails();
    this.displayDutiesAndTaxes();

    this.forwardingFileServiceSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
      .subscribe(file => {
        this.forwardingFile = file;
        this.companyService.getCompany().subscribe(companies => {
          if (companies && companies.length) {
            const branch: Branch = companies[0].branches[0];
            if (branch.invoicingInUse) {
              this.forwardingFile.houseBills.forEach(houseBill => {
                houseBill.invoicingInUse = branch.invoicingInUse;
              });
             }
            }
          });
        if (this.forwardingFile.houseBills) {
          if (this.forwardingFile.houseBills.length > 0) {
            const searchedHawb = this.forwardingFile.houseBills.find(houseBill => houseBill.hawbNo === this.selectedHouseBill.hawbNo);
            if (searchedHawb) {
              this.selectHouseBill(searchedHawb);
            } else {
              this.selectHouseBill(this.forwardingFile.houseBills[0]);
            }
          } else {
            const transportDocument: ForwardingTransportDocument = this.shareDataService.addTransportDocument('forwarding');
                      this.forwardingFile.houseBills.push(transportDocument);
                      this.forwardingFile.houseBills = [...this.forwardingFile.houseBills];
                this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
          }
          this.showTrackingButton = true;
        } else {
          this.createNewHawb();
          this.forwardingFile.houseBills = [this.selectedHouseBill];
          this.selectHouseBill(this.selectedHouseBill);
          this.transportDocumentBehaviourSubject.addTransportDocument(this.forwardingFile.houseBills[0]);
        }
        this.houseBillSelection = this.forwardingFile.houseBills;

        this.companyService.getCompany().subscribe(companies => {
          if (companies && companies.length) {
            const branch: Branch = companies[0].branches[0];
            if (branch && branch.forwardingLocalAgentCode && branch.forwardingLocalAgentCode.trim() !== '') {
              this.localAgentService
                .findLocalAgentNameOrCodeStartsWith(branch.forwardingLocalAgentCode)
                .subscribe(data => {
                  if (data) {
                    this.forwardingFile.houseBills.forEach(houseBill => {
                      if (!houseBill.localAgent) {
                        houseBill.localAgent = data[0];
                        houseBill.localAgent.address = data[0].addresses[0];
                        }
                      }
                    );
                  }
                });
              }
            }
          });
        });

    this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: ForwardingTransportDocument) => {
        if (td) {
          this.selectedHouseBill = td;
        }
      });

      const fileNumber = this.route.snapshot.paramMap.get('fileNumber');
          if (fileNumber) {
            this.forwardingFileService
              .loadForwardingFile(fileNumber, 'AIR')
              .subscribe(data => {
                console.log(data[0]);
                this.forwardingFileBehaviourSubject.addForwardingFile(this.forwardingFile = data[0]);
              });
          }
  }

  setTrackingStatus() {
    this.currentStatus = 'No Status Available';
    if (this.forwardingFile.masterBill) {
      if (this.forwardingFile.masterBill.trackingDetails) {
        if (this.forwardingFile.masterBill.trackingDetails.receivedAtJNBOPS) {
          this.currentStatus = 'Received At JNB OPS';
        } else if (this.forwardingFile.masterBill.trackingDetails.receivedAtORTIA) {
          this.currentStatus = 'Received At Airport';
        } else if (this.forwardingFile.masterBill.trackingDetails.releaseDate) {
          this.currentStatus = 'Received At Customs';
        } else if (this.forwardingFile.masterBill.trackingDetails.submissionDate) {
          this.currentStatus = 'Sent To Customs';
        }
      }
    }
  }

  track(transportDocument: ForwardingTransportDocument, isHouseBill: boolean) {
    this.setTrackingStatus();
    this.transportDocumentToTrack = transportDocument;
    if (!this.transportDocumentToTrack.trackingDetails) {
      this.transportDocumentToTrack.trackingDetails = new TrackingDetails();
    }

    if (this.forwardingFile.contrl) {
      this.transportDocumentToTrack.trackingDetails.submissionDate = this.forwardingFile.contrl.receivedDate;
    }

    if (this.forwardingFile.cusres) {
      this.transportDocumentToTrack.trackingDetails.releaseDate = this.forwardingFile.cusres.receivedDate;
    }
    this.isHouseBill = isHouseBill;
    this.displayTrackingDialog = true;
  }

  createNewHawb() {
    this.selectedHouseBill = new ForwardingTransportDocument();
    this.selectedHouseBill.measures = new MasterBillMeasures();
    this.selectedHouseBill.freight = new Freight();
    this.selectedHouseBill.customsValue = new CustomsValue();
    this.selectedHouseBill.customsValue.currency = new Currency();
    this.selectedHouseBill.importer = new Importer();
    this.selectedHouseBill.importer.address = new Address();
    this.selectedHouseBill.importer.registrationType = new CustomsTraderType();
    this.selectedHouseBill.supplier = new Supplier();
    this.selectedHouseBill.tariffCodes = [new TariffCode()];
    this.selectedHouseBill.freight.collectPrepaid = new CollectPrepaid();
    this.selectedHouseBill.freight.currency = new Currency();
    this.selectedHouseBill.departurePort = new PortDetail();
    this.selectedHouseBill.destinationPort = new PortDetail();
    this.selectedHouseBill.notifyPartyAddress = new Address();
    this.selectedHouseBill.transShipmentDetails = new TransShipmentDetails();
    this.selectedHouseBill.transShipmentDetails.transferPort = new PortDetail();
    this.selectedHouseBill.digiScan = new DigiScan();
    this.selectedHouseBill.containers = new Array<Container>();
  }

  handleIndexChange(index: number) {
    if (index === 0) {
      this.validateAndSaveForwardingFile();
    }
    if (index === 1) {
      this.updateRunningTotals();
      this.validateAndSaveForwardingFile();
    }
    if (index === 2) {
      this.validateAndSaveForwardingFile();
    }
    if (index === 3) {
      this.validateAndSaveForwardingFile();
    }
  }

  createNewFile() {
    if (this.router.url === '/forwarding/sea/manifests') {
      this.forwardingFileService.createNewFile('SEA');
    }
    if (this.router.url === '/forwarding/air/manifests') {
      this.forwardingFileService.createNewFile('AIR');
    }
  }

  addNewHawb() {
    this.createNewHawb();
    if (this.forwardingFile.houseBills) {
      this.forwardingFile.houseBills.push(this.selectedHouseBill);
    } else {
      this.forwardingFile.houseBills = [this.selectedHouseBill];
    }
    this.houseBillSelection = this.forwardingFile.houseBills;
  }

  searchHousebill() {
    const searchedHawb = this.forwardingFile.houseBills.find(houseBill => houseBill.hawbNo === this.hawbFilter);
    if (searchedHawb) {
      this.selectHouseBill(searchedHawb);
      this.houseBillSelection = [searchedHawb];
    } else {
      this.houseBillSelection = this.forwardingFile.houseBills;
      this.selectHouseBill(this.forwardingFile.houseBills[0]);
      this.tableFirst = 0;
    }
  }

  searchingHousebill() {
    const searchedHawb = this.forwardingFile.houseBills.find(houseBill => houseBill.hawbNo === this.selectedHouseBill.digiScan.scanHawb);
    if (searchedHawb) {
      this.selectedHouseBill.digiScan.importer = searchedHawb.importer;
      this.selectedHouseBill.digiScan.supplier = searchedHawb.supplier;
      this.selectedHouseBill.digiScan.customerReference = this.forwardingFile.masterBill.mawbNo;
      this.selectedHouseBill.digiScan.noOfItems = searchedHawb.packages[0].noOfPackages;
      this.selectedHouseBill.digiScan.descriptionOfGoods = searchedHawb.packages[0].description;

      // tslint:disable-next-line:max-line-length
      if (!searchedHawb.digiScan.specialInstruction || searchedHawb.digiScan.specialInstruction !== this.selectedHouseBill.digiScan.specialInstruction) {
        searchedHawb.digiScan.specialInstruction = this.selectedHouseBill.digiScan.specialInstruction;
      }
      if (!searchedHawb.digiScan.length || searchedHawb.digiScan.length !== this.selectedHouseBill.digiScan.length) {
        searchedHawb.digiScan.length = this.selectedHouseBill.digiScan.length;
      }
      if (!searchedHawb.digiScan.width || searchedHawb.digiScan.width !== this.selectedHouseBill.digiScan.width) {
        searchedHawb.digiScan.width = this.selectedHouseBill.digiScan.width;
      }
      if (!searchedHawb.digiScan.height || searchedHawb.digiScan.height !== this.selectedHouseBill.digiScan.height) {
        searchedHawb.digiScan.height = this.selectedHouseBill.digiScan.height;
      }
      if (!searchedHawb.digiScan.totalMass || searchedHawb.digiScan.totalMass !== this.selectedHouseBill.digiScan.totalMass) {
        searchedHawb.digiScan.totalMass = this.selectedHouseBill.digiScan.totalMass;
      }
      if (!searchedHawb.digiScan.volMass || searchedHawb.digiScan.volMass !== this.selectedHouseBill.digiScan.volMass) {
        searchedHawb.digiScan.volMass = this.selectedHouseBill.digiScan.volMass;
      }
      this.forwardingFile.masterBill.scanHawb = this.selectedHouseBill.digiScan.scanHawb;
      this.forwardingFile.masterBill.specialInstruction = this.selectedHouseBill.digiScan.specialInstruction;
    }
  }

  async saveDigiForwardingFile(): Promise<void> {
    this.searchingHousebill();
    const searchedHawb = this.forwardingFile.houseBills.find(houseBill => houseBill.hawbNo === this.selectedHouseBill.digiScan.scanHawb);
    this.forwardingFileService.save(this.forwardingFile).subscribe(forwardingFile => {
      this.messageService.successNotify('File Saved Successfully');
      this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
      this.selectHouseBill(searchedHawb);
    });
  }

  clearHawbSearch() {
    this.houseBillSelection = this.forwardingFile.houseBills;
    this.selectHouseBill(this.forwardingFile.houseBills[0]);
    this.hawbFilter = undefined;
    this.tableFirst = 0;
  }

  generateReport() {
    this.showProgressBar = true;
    // tslint:disable-next-line:max-line-length
    this.hawbReportsSubscription = this.forwardingFileService.generateHawbReport(this.forwardingFile.id, this.hawbFilter ? this.hawbFilter : null).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showDialog();
      this.showProgressBar = false;
    }, error => {
      this.showProgressBar = false;
      this.messageService.errorNotify(error.error.message, error.error.errors);
    });
  }

  selectHouseBill(value) {
    this.selectedHouseBill = value;
    this.transportDocumentBehaviourSubject.addTransportDocument(value);
    if (!this.selectedHouseBill.marksNumbersAndEndorsement) {
      const marksAndNumbers = new BillOfEntryMarksAndNumbers();
      marksAndNumbers.marksAndNumbers = [];
      marksAndNumbers.endorsements = [];
      this.selectedHouseBill.marksNumbersAndEndorsement = [marksAndNumbers];
    }

    if (!this.selectedHouseBill.packages || (this.selectedHouseBill.packages && !this.selectedHouseBill.packages.length)) {
      this.addNewPackage();
    } else {
      this.selectedPackage = this.selectedHouseBill.packages[0];
    }

    if (this.selectedHouseBill.containers && !this.selectedHouseBill.containers.length) {
      this.addContainer();
    }
    if (!this.selectedHouseBill.digiScan) {
       this.digiScan = new DigiScan();
      this.selectedHouseBill.digiScan = this.digiScan;
    }
  }
  searchAirline(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  selectAirline(value: any) {
    this.forwardingFile.masterBill.airline = value;
    this.forwardingFile.masterBill.airline.prefix = value.airlinePrefix;
  }

  selectMasterAirline(value: Airline) {
    this.forwardingFile.masterBill.masterAirline = value;
    this.forwardingFile.masterBill.masterAirline.prefix = value.airlinePrefix;
  }

  selectOverseasAgent(value: any) {
    this.forwardingFile.masterBill.overseasAgent = value;
  }

  selectCoLoader(value: any) {
    this.forwardingFile.masterBill.coLoader = value;
  }

  searchLocalForwarder(event: any) {
    this.localAgentService
      .findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod(event.query, 'AIR')
      .subscribe(data => {
        this.filteredLocalForwarders = data;
      });
  }

  searchDepotTerminal(event, depotTerminal) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, depotTerminal).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  updateCustomsOffice(customOffice) {
    this.forwardingFile.customOffice = customOffice;
  }

  selectWarehouse(value) {
    this.forwardingFile.warehouse = value;
  }

  onRowDelete(rowIndex: number) {
    this.forwardingFile.houseBills.splice(rowIndex, 1);
    this.forwardingFile.houseBills = [...this.forwardingFile.houseBills];
  }

  selectImporter(value: Importer) {
    this.selectedHouseBill.importer = value;
  }

  select(value) {
    if (this.onSelectUsed) {
      this.onSelect.emit(value);
    } else {
      this.currency.code = value.code;
      this.currency.name = value.name;
    }
  }

  selectDigiScanImporter(value: Importer) {
    this.selectedHouseBill.digiScan.importer = value;
  }

  selectLocalAgent(value: any) {
    this.selectedHouseBill.localAgent = value;
  }

  selectSupplier(value: any) {
    this.selectedHouseBill.supplier = value;
  }

  selectDigiScanSupplier(value: any) {
    this.selectedHouseBill.digiScan.supplier = value;
  }

  selectDebtor(value: any) {
    this.selectedHouseBill.debtor = value;
  }

  selectHouseOverseas(value: any) {
    this.selectedHouseBill.overseasAgent = value;
  }

  openImporterModal() {
    this.displayImporterModal = true;
    this.selectedHouseBill.importer.isEdited = true;
  }

  onImporterCreated(businessEntity: BusinessEntity) {
    let importer = new Importer();
    this.displayImporterModal = false;

    if (businessEntity && businessEntity.code) {
      this.importers.find(i => {
        if (i.code === businessEntity.code) {
          importer = i;
          return true;
        }
        return false;
      });
      this.onSelectImporter(importer);
    }

  }

  onSelectImporter(importer) {
    this.importerService.findImporterNameOrCodeStartsWith(importer.name)
      .subscribe(result => {
        this.importerAddresses = result[0].addresses;
      });
  }

  updateMarksAndNumbers(value) {
    this.selectedHouseBill.marksNumbersAndEndorsement = value;
  }

  addNewPackage() {
    this.selectedPackage = new Package();
    this.selectedPackage.goodsDescription = new GoodsDescription();
    this.selectedHouseBill.packages = [this.selectedPackage];
  }

  addContainer() {
    const container: Container = this.shareDataService.addContainer();
    this.selectedHouseBill.containers.push(container);
    this.selectedHouseBill.containers = [...this.selectedHouseBill.containers];
    this.containerBehaviorSubject.addContainer(container);
  }

  searchPackTypes(event) {
    this.packTypeService.findByCodeOrDescription(event.query).subscribe(
      data => this.filteredPackTypes = data
    );
  }

  selectPackType(value) {
    this.selectedPackage.packageType = value;
  }

  searchTariffCode(event) {
    this.tariffService
      .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  selectTariffCode(value) {
    this.selectedPackage.tariffCode = value;
  }

  showDialog() {
    this.display = true;
  }

  setBackgroundColor(isInvalid: boolean): string {
    if (isInvalid) {
      return 'red';
    } else {
      return 'transparent';
    }
  }

  updateRunningTotals() {
    this.forwardingFile.masterBill.runningTotals = new RunningTotals();

    this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills = this.forwardingFile.houseBills.length;

    this.forwardingFile.masterBill.runningTotals.remainingNoOfHouseBills =
      this.forwardingFile.masterBill.measures.numberOfHawbs -
      (this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills ?
        this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills : 0);

    // noOfPacks
    this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.numberOfPackages)
      .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100;

    // volume
    this.forwardingFile.masterBill.measures.volume = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.volume)
      .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100;

    this.forwardingFile.masterBill.runningTotals.remainingNoOfPacks = Math.round((this.forwardingFile.masterBill.measures.numberOfPackages -
      (this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks ?
        this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks : 0)) * 100) / 100;

    // weight
    this.forwardingFile.masterBill.runningTotals.capturedWeight = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.grossWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0) * 100) / 100;

    this.forwardingFile.masterBill.runningTotals.remainingWeight = Math.round((this.forwardingFile.masterBill.measures.grossWeight -
      (this.forwardingFile.masterBill.runningTotals.capturedWeight ?
        this.forwardingFile.masterBill.runningTotals.capturedWeight : 0)) * 100) / 100;

    // chargeableWeight
    this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight = this.forwardingFile.houseBills
      .map(hawb => hawb.measures.chargeableWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0);

    // noOfContainers
    this.forwardingFile.masterBill.measures.noOfContainers = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.noOfContainers)
      .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100;

    this.forwardingFile.masterBill.runningTotals.remainingChargeableWeight =
      this.forwardingFile.masterBill.measures.chargeableWeight -
      (this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight ?
        this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight : 0);
  }

  selectFiles(event: any) {
    const files = event.originalEvent.srcElement.files;
    this.file = files[0];
  }

  uploadManifest() {
    this.showProgressBar = true;
    if (this.forwardingFile && this.forwardingFile.id) {
      this.forwardingFileService.updateFromSpreadsheet(this.forwardingFile.id, this.file).subscribe(
          (file) => {
            this.forwardingFileBehaviourSubject.addForwardingFile(file);
            this.showProgressBar = false;
          },
          (error) => {
            console.error('Error during file upload:', error);
            this.showProgressBar = false;
          }
      );
    } else {
      this.forwardingFileService.createFromSpreadsheet(this.file, this.forwardingFile.transportMethodCode).subscribe(
          (response: any) => {
            const fileId = response.fileId;
            if (fileId) {
              this.pollFileStatus(fileId);
            } else {
              console.error('No fileId received from the backend.');
              this.showProgressBar = false;
            }
          },
          (error) => {
            console.error('Error during file upload:', error);
            this.showProgressBar = false;
          }
      );
    }
  }

  pollFileStatus(fileId: string) {
    const interval = setInterval(() => {
      this.forwardingFileService.getFileStatus(fileId).subscribe(
          (response: any) => {
            if (response.status) {
              console.log('File processing in progress...');
            } else {
              this.forwardingFileBehaviourSubject.addForwardingFile(response);
              this.showProgressBar = false;
              clearInterval(interval);
            }
          },
          (error) => {
            console.error('Error checking file status:', error);
            clearInterval(interval);
            this.showProgressBar = false;
          }
      );
    }, 5000);
  }

  isTransportMethodCodeSameAs(code: string): boolean {
    return this.forwardingFile && this.forwardingFile.transportMethodCode.toLowerCase() === code;
  }

  searchOceanLines(event: any) {
    this.oceanLineService.findAllOceanLineStartsWith(event.query).subscribe(data => {
      this.filteredOceanLines = data;
    });
  }

  searchRadioCallSigns(event, field) {
    this.customsRadioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(data => {
      this.filteredCustomsRadioCallSigns = data;
    });
  }

  saveForwardingFile() {
    this.forwardingFileService.save(this.forwardingFile).subscribe(forwardingFile => {
      this.messageService.successNotify('File Saved Successfully');
      this.sendTrackingEvent();
      this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
    });
  }

  saveForwardingFileAfterUpdatingTariffCodes() {
    const updatePromises = this.selectedHouseBill.tariffCodes.map(tariffCode =>
        this.tariffService.findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(tariffCode.code)
            .toPromise()
            .then(data => {
              const matchingResult = data.find(result => result.code === tariffCode.code);
              return matchingResult ? { ...matchingResult, foreignAmount: tariffCode.foreignAmount, weight: tariffCode.weight,
                units: tariffCode.units
              } : tariffCode;
            })
    );

    Promise.all(updatePromises)
        .then(updatedTariffCodes => {
          this.selectedHouseBill.tariffCodes = updatedTariffCodes;

          return this.forwardingFileService.save(this.forwardingFile).toPromise();
        })
        .then(savedFile => {
          this.forwardingFile = savedFile;
          this.messageService.successNotify('Tariff Codes and Foreign Amounts Updated');

          return this.forwardingFileService.calculateDuties(this.forwardingFile.id).toPromise();
        })
        .then(() => {
          this.messageService.successNotify('Duties calculated successfully.');

          return this.forwardingFileService.findByForwardingFileNo(this.forwardingFile.fileNumber).toPromise();
        })
        .then(latestFile => {
          this.forwardingFile = latestFile;

          return this.forwardingFileService.save(this.forwardingFile).toPromise();
        })
        .then(updatedFile => {
          this.forwardingFile = updatedFile;
          this.forwardingFileBehaviourSubject.addForwardingFile(updatedFile);
          this.messageService.successNotify('Tariff Codes, Foreign Amounts, and Forwarding File Saved');
        })
        .catch(error => {
          console.error('Error occurred:', error);
          this.messageService.errorNotify('An error occurred while processing the Forwarding File.');
        });
  }

  addTariffCode() {
    this.selectedHouseBill.tariffCodes.push(new TariffCode);
  }

  removeTariffCode(index: number) {
    this.selectedHouseBill.tariffCodes.splice(index, 1);
  }


  async validateAndSaveForwardingFile(): Promise<void> {
    // tslint:disable-next-line:max-line-length
      const validationResult: ValidationResult = await this.forwardingFileValidationService.validateManifests(this.forwardingFile, this.forwardingFile.transportMethodCode);
      if (validationResult.isValid) {
        this.saveForwardingFile();
      } else {
        this.messageService.errorNotify('Validation Failed', validationResult.messages);
      }
    }

  updateReceivedAtORTIAFieldsOnEachHouseBill() {
    this.updateHouseBills(this.forwardingFile.masterBill.trackingDetails.receivedAtORTIA, 'airport');
  }

  updateHouseBills(status: string, airportOpsIndicator: string) {
    if (this.forwardingFile.houseBills) {
      for (const houseBill of this.forwardingFile.houseBills) {
        if (!houseBill.trackingDetails) {
          houseBill.trackingDetails = new TrackingDetails();
        }
        if (airportOpsIndicator === 'ops') {
          houseBill.trackingDetails.receivedAtJNBOPS = status;
        } else {
          houseBill.trackingDetails.receivedAtORTIA = status;
        }
      }
      this.forwardingFileService.save(this.forwardingFile).subscribe(_ => {
        this.messageService.successNotify('House Bills Updated');
        this.setTrackingStatus();
      });
    }
  }

  updateReceivedAtJNOPSFieldsOnEachHouseBill() {
    this.updateHouseBills(this.forwardingFile.masterBill.trackingDetails.receivedAtJNBOPS, 'ops');
  }

  sendUpdate(status: any) {
    this.showTrackingProgressBar = true;
    const references: Array<string> = [];
    if (this.forwardingFile.houseBills) {
      for (const houseBill of this.forwardingFile.houseBills) {
        references.push(houseBill.hawbNo);
      }
      this.forwardingFileService.sendParcelUpdates(references, status)
        .subscribe(_ => {
          this.messageService.successNotify('Successfully Updated API.');
          this.showTrackingProgressBar = false;
        });
    }
  }

  validateHawbNo() {
    this.forwardingFileService.findForwardingFileNumbersByHawbNo(this.selectedHouseBill.hawbNo, this.forwardingFile.fileNumber).subscribe(
      res => {
        if (res.length > 0) {
          const message = ' [' + this.selectedHouseBill.hawbNo + ']' + ' is also used in [' + res.join(', ') + ']';
          const foundDetail = this.validationMessagesForHawbNo.find(el => el.detail === message);
          if (!foundDetail) {
            this.validationMessagesForHawbNo.push({severity: 'warn', summary: 'Duplicate HAWB No.', detail: message});
          }
        } else {
          this.validationMessagesForHawbNo.length = 0;
        }
      }, _ => this.validationMessagesForHawbNo.length = 0
    );
  }

  getStatusAndEvents() {
    this.forwardingFileService.getStatusCodesAndEvents()
      .subscribe(_ => {});
  }
  search(event) {
    this.currencyService.findCurrencyStartWith(event.query).subscribe(data => {
      this.filteredCurrencies = data;
    });
  }

  displayCustomsValue(): void {
    this.displayCustomsValuePanel = false;
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.customsValueInUse) {
          this.displayCustomsValuePanel = true;
        }
      }
    });
  }

  displaySupplierInvoiceDetails(): void {
    this.displaySupplierInvoicePanel = false;
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.supplierInvoiceInUse) {
          this.displaySupplierInvoicePanel = true;
        }
      }
    });
  }

  displayTariffCodesDetails(): void {
    this.displayTariffCodesPanel = false;
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.tariffCodesInUse) {
          this.displayTariffCodesPanel = true;
        }
      }
    });
  }

  displayDutiesAndTaxes(): void {
    this.displayDutiesAndTaxesPanel = false;
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.dutiesAndTaxesInUse) {
          this.displayDutiesAndTaxesPanel = true;
        }
      }
    });
  }

  navigateToSite() {
    window.open('https://www.track-trace.com/aircargo', '_blank');
  }

  ngOnDestroy() {
    this.forwardingFileServiceSubscription.unsubscribe();
    this.tdSubscription.unsubscribe();
  }

  print() {
    window.print();
  }

  deleteLine(index: number): void {
    this.rows.splice(index, 1);
  }

  addNewLine(): void {
    this.rows.push({
      noOfItems: null,
      descriptionOfGoods: '',
      length: null,
      width: null,
      height: null,
      totalMass: null,
      volMass: null
    });
  }
  // to calculate volume mass
  calculateVMass(digiScan: DigiScan): number {
    const length = Number(digiScan.length);
    const width = Number(digiScan.width);
    const height = Number(digiScan.height);

    if (!isNaN(length) && !isNaN(width) && !isNaN(height)) {
      return (length * width * height) / 5000;
    } else {
      return 0;
    }
  }

  generateDA306() {
    this.disableButton = true;
    this.forwardingFileService.generateDA306(this.forwardingFile.id).subscribe(data => {
        this.pdfSrcc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`;
      },
      error => {
        this.notificationService.errorNotify(error.error.message, error.error.errors);
        this.pdfSrcc = null;
        this.disableButton = false;
      });
  }


  openLink() {
    window.open(this.pdfSrcc, '_blank');
  }

  loadGeneratedFiles() {
    this.saveForwardingFile();
    this.disableButton = true;
    this.forwardingFileService.loadFiles(this.forwardingFile.id).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showFilesDialog = true;
      this.disableButton = false;
    }, error => {
      this.disableButton = false;
    });
  }

    get totalCustomsDuty(): number {
        return parseFloat(this.selectedHouseBill.tariffCodes.reduce((sum, tariff) => {
            return sum + (tariff.customsDuty || 0);
        }, 0).toFixed(2));
    }

    get totalATV(): number {
        return parseFloat(this.selectedHouseBill.tariffCodes.reduce((sum, tariff) => {
            return sum + (tariff.atv || 0);
        }, 0).toFixed(2));
    }

    get totalVAT(): number {
        return parseFloat(this.selectedHouseBill.tariffCodes.reduce((sum, tariff) => {
            return sum + (tariff.vat || 0);
        }, 0).toFixed(2));
    }

    get totalPayable(): number {
        return parseFloat(this.selectedHouseBill.tariffCodes.reduce((sum, tariff) => {
            return sum + (tariff.totalPayable || 0);
        }, 0).toFixed(2));
    }

  sendTrackingEvent() {
    const currentDate = new Date();
    const currentDateStr = currentDate.toISOString().split('T')[0];
    const currentTimeStr = currentDate.toTimeString().split(' ')[0];

    this.forwardingFile.houseBills.forEach(housebill => {

      const trackingDetails = housebill.trackingDetails || {};

      const trackingEvents = [
        {
          condition: trackingDetails.statusPickedUpByCustomsClearanceCompany || '',
          status: 6,
          description: 'Picked up by customs clearance Company'
        },
        {
          condition: trackingDetails.statusImportCustomsHandover || '',
          status: 7,
          description: 'Import customs handover'
        },
        {
          condition: trackingDetails.statusCarrierPickedUp || '',
          status: 8,
          description: 'Carrier picked up'
        },
        {
          condition: trackingDetails.statusTaxDutyCollection || '',
          status: 9,
          description: 'Tax duty collection'
        }
      ];

      trackingEvents.forEach(event => {
        if (event.condition) {
          const request: SendTrackingEventRequest = {
            eventID: this.generateUniqueEventID(),
            waybillNumber: housebill.hawbNo,
            eventDate: currentDateStr,
            eventTime: currentTimeStr,
            status: event.status,
            statusDescription: event.description
          };

          this.forwardingFileService.sendTrackingEvent(request).subscribe({
            next: (success) => {
              if (success) {
                console.log(`Tracking event sent successfully for housebill: ${housebill.hawbNo}`);
                console.log(request.status);
                console.log(request.statusDescription);
              } else {
                console.error(`Failed to send tracking event for housebill: ${housebill.hawbNo}`);
              }
            },
            error: (err) => {
              console.error(`Error sending tracking event for housebill: ${housebill.hawbNo}`, err);
            }
          });
        }
      });
    });
  }

  private generateUniqueEventID(): string {
    return Math.floor(Math.random() * 1000000).toString();
  }



}
