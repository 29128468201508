import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {ForwardingFile, ForwardingTransportDocument, SendTaxesRequest, SendTrackingEventRequest} from '../models/ForwardingFile';
import {Observable, of} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {ForwardingFileBehaviourSubject} from '../../../subjects/forwarding-file-behaviour-subject';
import {TransportDocumentBehaviourSubject} from '../../../subjects/transport-document-behaviour-subject';
import {ContainerBehaviorSubject} from '../../../subjects/container-behavior-subject';
import {ShareDataService} from './share-data.service';
import {HouseBill} from '../models/houseBill';

@Injectable({
  providedIn: 'root'
})
export class ForwardingFileService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private shareDataService: ShareDataService) {
  }

  save(forwardingFile: ForwardingFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (forwardingFile._links && forwardingFile._links.self) {
      return this.http.put(this.config.filesApiEndpoint + 'api/forwardingFiles/' + forwardingFile.id, JSON.stringify(forwardingFile), {headers});
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles', JSON.stringify(forwardingFile), {headers});
    }
  }

  getStatusCodesAndEvents(): Observable<any> {
    return this.http.get( this.config.filesApiEndpoint + 'api/forwardingFiles/tracking');
  }

  sendParcelUpdates(references: string[], status: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put( this.config.filesApiEndpoint + 'api/forwardingFiles/tracking/'  + references + '/' + status, {headers});
  }

  loadForwardingFile(fileNumber: string, transportMethodCode: string): Observable<any> {
    if (fileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/forwardingFiles/search/fileNumberStartsWith?fileNumber=${fileNumber}&transportMethodCode=${transportMethodCode}`)
      .pipe(
        map(response => response['_embedded'].forwardingFiles)
      );

  }

  loadForwardingFilefromMawb(mawbNumber: string): Observable<any> {
      if (mawbNumber === '') {
        return of([]);
      }
      return this.http
        .get(`${this.config.filesApiEndpoint}api/forwardingFiles/search/findByMawbNo?mawbNo=${mawbNumber}`)
        .pipe(
          map(response => response['_embedded'].forwardingFiles)
        );

    }

  createNewFile(transportMethod?: string) {
    this.forwardingFileBehaviourSubject.addForwardingFile(this.shareDataService.buildForwardingFile(transportMethod));
    this.containerBehaviorSubject.addContainer(this.shareDataService.addContainer());
  }

  selectForwardingFile(value) {
    this.createNewFile();
    this.forwardingFileBehaviourSubject.addForwardingFile(value);
    if (value.houseBills.length > 0) {
      this.transportDocumentBehaviourSubject.addTransportDocument(value.houseBills[0]);
      if (value.houseBills[0].containers && value.houseBills[0].containers.length > 0) {
        this.containerBehaviorSubject.addContainer(value.houseBills[0].containers[0]);
      }
    }
  }

  generate(id: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/forwardingFiles/${id}/documents`, {});
  }

  generateHawbReport(fileId: string, hawbFilter: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/hawbReport?fileId=${fileId}&hawbFilter=${hawbFilter}`);
  }

  findByFileNoAndSequence(fileNumber: string, sequence: number) {
    if (fileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/forwardingFiles/search/fileNumberStartsWith?fileNumber=` + fileNumber)
      .pipe(
        map(response => response['_embedded'].forwardingFiles)
      );
  }

  createFromSpreadsheet(file: any, transportMethodCode: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.config.filesApiEndpoint + 'api/forwardingFiles/csvUpload?transportMethodCode=' + transportMethodCode, formData);
  }

  getFileStatus(fileId: string): Observable<any> {
    return this.http.get(this.config.filesApiEndpoint + `api/forwardingFiles/fileStatus/${fileId}`);
  }

  updateFromSpreadsheet(id: string, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.put(this.config.filesApiEndpoint + `api/forwardingFiles/${id}/csvUpload`, formData);
  }

  createClearingFilesFromHawbs(id: string): Observable<any> {
    const formData = new FormData();
    return this.http.post(this.config.filesApiEndpoint + `api/forwardingFiles/clearingFromForwardingFile?id=${id}`, formData);
  }

  findByMawbNo(mawbNo: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/forwardingFiles/search/findByMawbNo?mawbNo=${mawbNo}`)
      .pipe(
        map(response => response['_embedded'].forwardingFiles)
      );
  }

  findByForwardingFileNo(fileNumber: string): Observable<any> {
    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/search/findByForwardingFileNo?fileNumber=${fileNumber}`;
    return this.http.get<any>(url);
  }

  getAllForwardingFiles(page: number, size: number): Observable<any[]> {
    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/forwarding-files?page=${page}&size=${size}`;
    return this.http.get<any[]>(url);
  }

  getAllRcgFiles(page: number, size: number): Observable<any> {
    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/findAllAirManifestFiles?page=${page}&size=${size}&sort=fileNumber, desc`;
    return this.http.get(url);
  }

  findRcgFilesByMawbNo(mawbNo: string): Observable<any> {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/forwardingFiles/search/findByMawbNoAndFromJsonOrManifest?mawbNo=${mawbNo}`);
  }

  getTotalForwardingFilesCount(): Observable<number> {
    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/forwarding-files/total-count`;
    return this.http.get<number>(url);
  }

  createListOfHousebills(files: ForwardingFile[]): any[] {
    const housebills = [];
    files.forEach(file => {
      file.houseBills.forEach(h => {
        const customHouseBill = Object.assign({}, h);
        customHouseBill.mawbNo = file.masterBill.mawbNo;
        customHouseBill.mawbDate = file.masterBill.mawbDate;
        customHouseBill.flight = file.masterBill.flight;
        customHouseBill.departureDate = file.masterBill.departureDate;
        customHouseBill.arrivalDate = file.masterBill.arrivalDate;
        customHouseBill.airline = Object.assign({}, file.masterBill.airline);
        customHouseBill.forwardFile = {forwardingFileNumber: file.fileNumber};
        customHouseBill.depotTerminal =  file.masterBill.depot;
        customHouseBill.customsOffice = Object.assign({}, file.customOffice);
        customHouseBill.localForwarder = file.masterBill.localForwarder;
        housebills.push(customHouseBill);
      });
    });
    return housebills;
  }

  getHouseBillByHouseBillNumber(houseBillNo: string): Observable<HouseBill | null> {
    if (houseBillNo === '') {
      return of(null);
    }
    const parameters = new HttpParams().set('houseBillNo', houseBillNo);
    return this.http.get<HouseBill>(`${this.config.filesApiEndpoint}api/forwardingFiles/search/findByHousebillNo`, {params: parameters});
  }

  validateHouseBills(houseBills: ForwardingTransportDocument[]): Observable<any> {
    return this.http
      .post(`${this.config.filesApiEndpoint}api/forwardingFiles/search/validateHawbNos`, houseBills);
  }

  updateScannedHouseBills(houseBills: HouseBill[], status: string) {
    return this.http.put(this.config.filesApiEndpoint + `api/forwardingFiles/updateHouseBills/${status}`, houseBills);
  }

  findForwardingFileNumbersByHawbNo(hawbNo: string, fileNumber: string): Observable<string[]> {
    return this.http.get(`${this.config.filesApiEndpoint}api/forwardingFiles/search/findForwardingFileNumbersByHawbNo?forwardingFileNumber=${fileNumber}&hawbNo=${hawbNo}`)
      .pipe(
        map((response: any[]) => response.map(el => el.fileNumber))
      );
  }

  loadFiles(id: String): Observable<any> {
    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/` + id + '/digiScan';
    return this.http.get(url);
  }

  generateDA306(id: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/forwardingFiles/` + id + '/da306');
  }

  calculateDuties(id: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/forwardingFiles/` + id + '/calculate-duties');
  }

  validateForwardingFile(id: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/forwardingFiles/` + id + '/validateForwardingFile');
  }

  sendTrackingEvent(request: SendTrackingEventRequest): Observable<boolean> {

    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/sendTrackingEvent`;

    return this.http.post<boolean>(url, request).pipe(
        map(response => {
          return response;
        }),
        catchError((error) => {
          console.error('Error sending tracking event', error);
          return of(false);
        })
    );
  }

  sendTaxes(request: SendTaxesRequest): Observable<boolean> {

    const url = `${this.config.filesApiEndpoint}api/forwardingFiles/sendTaxes`;

    return this.http.post<boolean>(url, request).pipe(
        map(response => {
          return response;
        }),
        catchError((error) => {
          console.error('Error sending taxes', error);
          return of(false);
        })
    );
  }


}
