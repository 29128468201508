import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var CompanyService = /** @class */ (function () {
    function CompanyService(http, config) {
        this.http = http;
        this.config = config;
    }
    CompanyService.prototype.getCompany = function () {
        return this.http.get(this.config.filesApiEndpoint + "api/company").pipe(map(function (response) { return response['_embedded'].company; }));
    };
    CompanyService.prototype.save = function (company) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        if (company._links && company._links.self) {
            return this.http.put(company._links.self.href, JSON.stringify(company), { headers: headers });
        }
        else {
            return this.http.post(this.config.filesApiEndpoint + "api/company", JSON.stringify(company), { headers: headers });
        }
    };
    CompanyService.prototype.getEndpointForRcgPaymentNotifications = function () {
        return this.config.filesApiEndpoint;
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
